<template>
  <div>
    <list-table
      ref="tableRef"
      :columns="columns"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :title="title"
      create-event
      identifier="commentsTable"
      @create="createButtonClicked"
    />

    <create-or-update-comment-modal
      ref="modal"
      :call-endpoint-func="callEndpoint.bind(this)"
      :comment-id="activeCommentId"
      :commentable-id="commentableId"
      :endpoint-types="ENDPOINT_TYPES"
      :endpoints="endpoints"
      :mode="mode"
      @change="onCommentSaved"
    />
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import CreateOrUpdateCommentModal from "@/components/forms/comments/CreateOrUpdateCommentModal";
import {FILTER_BOOLEAN} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowCommentsTable",
  components: {CreateOrUpdateCommentModal, ListTable,},
  props: {
    endpoints: {
      type: Array,
      required: true
    },
    commentableId: {
      type: Number,
      required: true
    },
  },
  data() {
    const vm = this;
    const ENDPOINT_TYPES = {
      GET: 'get',
      LIST: 'list',
      UPDATE: 'update',
      CREATE: 'create',
      DELETE: 'delete',
    };

    return {
      ENDPOINT_TYPES,

      mode: 'create',
      activeCommentId: null,

      title: 'Commentaires',
      repositoryFunc: (...args) => {
        return this.callEndpoint(ENDPOINT_TYPES.LIST, this.commentableId, ...args);
      },
      columns: [
        {
          caption: "Actions",
          showInColumnChooser: false,
          allowReordering: false,
          buttons: [
            {
              type: 'edit',
              hint: 'Modifier commentaire',
              onClick: (row) => this.editButtonClicked(row),
            },
            {
              type: 'delete',
              hint: 'Supprimer commentaire',
              confirmation: {
                title: 'Supprimer commentaire?',
                text: 'Étes vous sure que vous voulez supprimer ce commentaire?'
              },
              successText: 'Le commentaire a été supprimé',
              deleteAction: (row) => this.callEndpoint(ENDPOINT_TYPES.DELETE, vm.commentableId, row.id)
            },
          ],
        },
        {
          caption: 'Commentaire',
          dataField: 'text',
          sort: {dataField: 'id'}
        },
        {
          caption: 'De',
          dataField: 'authorMember.name',
        },
        {
          caption: 'Date d\'expiration',
          calculateCellValue: (r) => {
            if (r.expiresAt === null) {
              return "Jamais";
            }

            return this.$date(r.expiresAt).format('DD.MM.YYYY HH:mm');
          },
          sort: {dataField: 'expiresAt'}
        },
        {
          caption: 'Crée',
          calculateCellValue: (r) => {
            if (r.createdAt === null) {
              return "";
            }

            return this.$date(r.createdAt).format('DD.MM.YYYY HH:mm');
          },
          sort: {dataField: 'createdAt'}
        },
        {
          caption: 'Dernière modification ',
          calculateCellValue: (r) => {
            if (r.updatedAt === null) {
              return "";
            }

            return this.$date(r.updatedAt).format('DD.MM.YYYY HH:mm');
          },
          sort: {dataField: 'updatedAt'}
        },
      ],
      filters: [
        {
          caption: 'Montrer seulement non expiré',
          filterKey: 'isStillValid',
          type: FILTER_BOOLEAN,
          defaultValue: 1,
          small: true
        },
      ]
    };
  },
  methods: {
    callEndpoint(type, ...args) {
      let endpoint = this.endpoints.find(t => t.type === type);
      if (!endpoint) {
        console.error(`[Comments] Endpoint with type >${type}< not found.`);
        return;
      }

      return endpoint.func(...args);
    },
    createButtonClicked() {
      this.activeCommentId = null;
      this.mode = 'create';

      this.$nextTick(() => {
        this.$refs.modal.fetchOrInitiate();
      });
    },
    editButtonClicked(row) {
      this.activeCommentId = row.id;
      this.mode = 'update';

      this.$nextTick(() => {
        this.$refs.modal.fetchOrInitiate();
      })
    },

    onCommentSaved() {
      this.$refs.tableRef.refresh();
    }
  },
}
</script>

