<template>
  <modal
    ref="commentModal"
    :show-close-button="true"
    title="Commentaire"
  >
    <div class="w-96">
      <form-wrapper
        ref="form"
        :show-changed="false"
        :show-submit-button="mode !== 'show'"
        :submit-button-label="mode === 'create' ? 'Créer commentaire' : 'Sauvegarder commentaire'"
        @submit="createOrUpdateComment"
      >
        <form-grid>
          <text-area-element v-model="comment.text" label="Commentaire"/>
          <date-picker-element
            v-model="comment.expiresAt"
            label="Date d'expiration"
          />
        </form-grid>
      </form-wrapper>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/elements/modals/Modal";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import FormGrid from "@/components/elements/forms/FormGrid";
import TextAreaElement from "@/components/elements/forms/elements/base/TextAreaElement";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement";

export default {
  name: "CreateOrUpdateCommentModal",
  components: {DatePickerElement, TextAreaElement, FormGrid, FormWrapper, Modal},
  data: () => ({
    comment: {
      text: '',
      expiresAt: null
    }
  }),
  props: {
    endpoints: {type: Array,},
    endpointTypes: {type: Object,},
    callEndpointFunc: {type: Function,},

    mode: {type: String, default: ''},
    commentableId: {type: Number},
    commentId: {type: Number},
  },
  methods: {
    fetchOrInitiate() {
      if (this.mode === "update" && this.commentId !== null) {
        this.callEndpointFunc(this.endpointTypes.GET, this.commentableId, this.commentId).then(res => {
          this.comment.text = res.data.data.text;
          this.comment.expiresAt = res.data.data.expiresAt;
          this.$nextTick(() => {
            this.$refs.commentModal.openModal();
          });
        })
      } else {
        this.comment = {
          text: '',
          expiresAt: null
        };
        this.$nextTick(() => {
          this.$refs.commentModal.openModal();
        });
      }
    },

    createOrUpdateComment(callback) {
      if (this.mode === 'update') {
        this.callEndpointFunc(this.endpointTypes.UPDATE, this.commentableId, this.commentId, {
          text: this.comment.text,
          expiresAt: this.comment.expiresAt
        }).then(() => {
          this.$sendSuccessToast('Commentaire sauvegardé!');
          this.$emit('change');
          this.resetForm();
          this.closeModal();
        }).finally(() => callback());
      } else {
        this.callEndpointFunc(this.endpointTypes.CREATE, this.commentableId, {
          text: this.comment.text,
          expiresAt: this.comment.expiresAt
        }).then(() => {
          this.$sendSuccessToast('Commentaire crée!');
          this.$emit('change');
          this.resetForm();
          this.closeModal();
        }).finally(() => callback());
      }
    },

    resetForm() {
      this.$refs.form.resetForm();
    },

    closeModal() {
      this.$refs.commentModal.closeModal();
    },
  },
}
</script>

